import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import aora from "../../Assets/Projects/aora.jpg";
import paradise from "../../Assets/Projects/paradise.png";
import react from "../../Assets/Projects/react.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={aora}
              isBlog={false}
              title="Aora Social Media Video Upload App"
              description="Aora is a dynamic, cross-platform React Native application accessible via a QR code in the demo section, requiring the Expo Go app. The app enables users to create accounts, explore videos uploaded by other users, and contribute their own content. It is backed by a robust database where all user data and video content are securely stored and managed. Data is efficiently fetched from the database using a custom-built API, ensuring seamless content delivery and a smooth user experience across both Android and iOS platforms."
              ghLink="https://github.com/Harshit-Nigam/ReactNative/tree/main"
              demoLink="https://expo.dev/preview/update?message=Aora%20app%20created%20by%20harshit&updateRuntimeVersion=1.0.0&createdAt=2024-08-29T08%3A06%3A07.141Z&slug=exp&projectId=abc8662f-d6d9-4038-bf59-2ce96bc69bc7&group=27c9f650-59fe-4d3a-8bc7-a19f63bf05eb"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={paradise}
              isBlog={false}
              title="Photography Website"
              description="This photography website, created and hosted by me, showcases a diverse portfolio of images and photography projects. The site is fully functional with a custom domain name, ensuring a professional online presence. It features an intuitive gallery layout, high-resolution image display, and smooth navigation to enhance user experience. The website is built with modern web technologies and is hosted on a live server, offering fast load times and reliable performance. Visitors can explore various photography collections and enjoy a seamless browsing experience on both desktop and mobile devices."
              ghLink="https://github.com/Harshit-Nigam/ayushsaxena"
              demoLink="http://paradisephotography.in/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={react}
              isBlog={false}
              title="Shopping Website Frontend"
              description="This shopping website, developed using React, features a dynamic and responsive frontend with reusable components for an optimal user experience. The site is hosted live with a custom domain, providing fast access and reliable performance. Key features include a user-friendly interface for browsing products, a shopping cart system, and seamless checkout processes. The modular design, built with reusable React components, ensures easy maintenance and scalability. The website is optimized for both desktop and mobile devices, delivering a smooth and engaging shopping experience for all users."
              ghLink="https://github.com/Harshit-Nigam/"
              demoLink="https://famous-kataifi-a3bcd2.netlify.app/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
